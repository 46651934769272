import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import appStyles from "./apps.module.css"
import kinderliedjesLogo from "../images/kinderliedjes.png"
import kodiFreeLogo from "../images/remote_free.png"

export default function Apps() {
  return (
    <Layout>
      <h1>Apps</h1>
      <p>
        Om het mobiele platform te verkennen, ontwikkelt CRW Solutions App's.
      </p>
      <p>&nbsp;</p>
      <h2>Apple app-store.</h2>
      <div className={appStyles.appContainer}>
        <img className={appStyles.appImg} src={kinderliedjesLogo} />
        <div className={appStyles.appText}>
          <h2>
            <strong>kinderliedjes</strong>
          </h2>
          <p>
            Een kinderliedjes programma. Dit programma geeft de teksten van de
            meest populaire kinderliedjes. Handig als je het even niet meer
            weet, en je dochtertje of zoontje wil graag even met je zingen.
          </p>
          <p>
            <a href="http://itunes.apple.com/us/app/kinderliedjes/id446356208?mt=8&amp;ls=1">
              link app store
            </a>
          </p>
          <p>
            Heeft u een vraag over kinderliedjes? Kijkt u dan eens op&nbsp;
            <Link to="/kinderliedjes-veel-gestelde-vragen">
              kinderliedjes: veel gestelde vragen
            </Link>
          </p>
        </div>
      </div>
      <p>&nbsp;</p>
      <h2>Windows market place</h2>
      <div className={appStyles.appContainer}>
      <img className={appStyles.appImg} src={kodiFreeLogo} />
      <div className={appStyles.appText}>
        <h2>
          <strong>Kodi remote free</strong>
        </h2>
        <p>Een gratis kodi remote. Gemaakt voor versie 11 en later van kodi.</p>
        <p>
          <a href="http://www.windowsphone.com/nl-nl/store/app/xbmc-remote-free/3a35a8c6-bf35-49a1-b67b-f8104be2b05c">
            link&nbsp;windows market place
          </a>
        </p>
        </div>
      </div>
    </Layout>
  )
}
